/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29/05/2023.
 */
import React from 'react'
import { Link } from 'gatsby'
import { useHover } from '@use-gesture/react'

import { animated, useSpring } from '@react-spring/web'

const FooterPillButton = ({ to, label }) => {
  const bind = useHover(({ active }) => {
    api({
      background: active ? 'white' : 'black',
      color: active ? 'black' : 'white',
    })

    textApi({ x: active ? 15 : 0 })
  })

  const [aniStyle, api] = useSpring(() => ({
    background: 'black',
    color: 'white',
    config: { mass: 2, tension: 250, friction: 10, clamp: true },
  }))
  const [textStyle, textApi] = useSpring(() => ({
    x: 0,
    config: { mass: 2, tension: 250, friction: 20, clamp: true },
  }))

  return (
    <Link to={to} {...bind()} className="text-decoration-none mw-100 text-white">
      <animated.div style={aniStyle} className="border-2 border rounded-pill py-4 px-5 mb-3">
        <div className="text-decoration-none mb-0 d-flex justify-content-between align-items-center">
          <animated.h5 style={textStyle} className="lead mb-0">
            {label}
          </animated.h5>

          <animated.svg width="24" height="24" fill={aniStyle.color} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.05 19.071l1.414 1.414L16.95 12 8.464 3.515 7.05 4.929l7.071 7.07-7.07 7.072z" />
          </animated.svg>
        </div>
      </animated.div>
    </Link>
  )
}
export default FooterPillButton
