/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/05/2022.
 */

export const PRODUCT_PAGE = { title: 'Produkter', to: `/`, visible: true, sub: true }

export const KITCHENS_PAGE = {
  title: 'Køkken',
  to: `/koekken`,
  visible: true,
  gotoPage: (model) => {
    return '/koekken'
  },
}
export const KITCHENS_MODEL_PAGE = {
  title: 'Køkken',
  to: `/koekken/:model`,
  visible: false,
  gotoPage: (model) => {
    return '/koekken/' + model.toLowerCase()
  },
}

export const DRAWER_PAGE = {
  title: 'Træskuffer',
  to: `/skuffer`,
  visible: false,
  gotoPage: (model) => {
    return '/skuffer'
  },
}

export const WARDROBE_PAGE = {
  title: 'Garderobe',
  to: `/garderobe`,
  visible: false,
  gotoPage: (model) => {
    return '/garderobe'
  },
}

export const WARDROBE_MODEL_PAGE = {
  title: 'Garderobe',
  to: `/garderobe/:model`,
  visible: false,
  gotoPage: (model) => {
    return '/garderobe/' + model.toLowerCase()
  },
}

export const BATHROOM_MODEL_PAGE = {
  title: 'Garderobe',
  to: `/bademoebler/:model`,
  visible: false,
  gotoPage: (model) => {
    return '/bademoebler/' + model.toLowerCase()
  },
}

export const BATHROOM_PAGE = {
  title: 'Bademøbler',
  to: `/bademoebler`,
  visible: false,
  gotoPage: (model) => {
    return '/bademoebler'
  },
}

export const COLOR_PAGE = { title: 'Farver', to: '/farver/', visible: true }

export const HOME = { title: 'Forside', to: '/', visible: true }
export const HOW_PAGE = { title: 'Hvordan', to: '/hvordan/', visible: true }
export const CONTACT_PAGE = { title: 'Kontakt', to: '/kontakt/', visible: true }
export const PRICE_PAGE = { title: 'Priser', to: `/priser/`, visible: true }
export const SHOWROOM_PAGE = { title: 'showroom', to: `/showroom`, visible: true }

export const PAGES = [HOME, PRODUCT_PAGE, /*COLOR_PAGE,*/ HOW_PAGE, CONTACT_PAGE]
