/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28-07-2020.
 */
import React, { useRef, useState } from 'react'
import MenuSvg from '../../assets/menu.svg'
import CloseSvg from '../../assets/close.svg'

import { Link } from 'gatsby'
import { CONTACT, NAV_DESELECTED_COLOR } from '../../constant'
import { CONTACT_PAGE, DRAWER_PAGE, HOME, HOW_PAGE, KITCHENS_PAGE, WARDROBE_PAGE } from '../../pages'
import { animated, useTransition } from '@react-spring/web'

const MobileNavigation = () => {
  const cornerMenu = useRef()

  const mobilPages = [HOME, KITCHENS_PAGE, WARDROBE_PAGE, DRAWER_PAGE, HOW_PAGE, CONTACT_PAGE]
  const [menuState, setMenuState] = useState(false)
  //  const containerRef = useRef(null)
  const toggle = () => {
    setMenuState(!menuState)
  }

  /*const headerTrans = useTransition(menuState, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },

    config: { friction: 40, tension: 325 },
  })*/

  const containTrans = useTransition(menuState, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },

    config: { friction: 40, tension: 325 },
  })

  const transition = useTransition(menuState ? mobilPages : [], {
    from: { opacity: 0, transform: 'translate3d(50px, 0px, 0px)' },
    enter: {
      opacity: 1,
      transform: 'translate3d(0px, 0px, 0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(50px, 0px, 0px)',
    },
    trail: 300 / mobilPages.length,
    config: { friction: 40, tension: 325 },
  })

  const handleKeyDown = (ev) => {
    // check keys if you want
    if (ev.keyCode === 13) {
    }
  }

  return (
    <div
      className="container-fluid position-fixed d-block d-md-none "
      style={{
        zIndex: 100,
      }}
    >
      <div className="row" style={{}}>
        <div
          ref={cornerMenu}
          className="col-12 d-flex justify-content-between position-relative"
          style={{ zIndex: 20 }}
        >
          {/*<div className="d-flex my-3 justify-content-between w-75">
            <Link to="/">
              <Logo className=" " style={{ marginLeft: 12, height: '1.2rem' }}></Logo>
            </Link>
            {menuState ? null : (
              <Link to="/kontakt" className="mt-0 me-1 text-decoration-none  ">
                <Button className="py-1 " title="Bestil nu"></Button>
              </Link>
            )}
          </div>*/}

          <div
            onClick={toggle}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex="0"
            alt="menu"
            aria-label="navigation-menu"
            className="position-absolute"
            style={{ top: -2, right: 0 }}
          >
            <svg className="" width="85" height="86" viewBox="0 0 85 86" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M85 86C38.0558 86 0 47.7203 0 0.5C0 0.5 38.0558 0.5 85 0.5L85 86Z" fill="#000000" />
            </svg>
          </div>
          <div></div>
          <div className="position-relative pe-5 mt-4 no-clickable noselect">
            <div
              className="position-absolute"
              style={{
                top: 0,
                left: 0,
                width: 'auto',
                height: '1.2rem',
                opacity: menuState ? 0 : 1,
              }}
            >
              <MenuSvg style={{ height: 28, width: 28 }}></MenuSvg>
            </div>

            <div
              className="position-absolute"
              style={{
                top: -8,
                left: -8,
                width: 'auto',
                height: '2.3rem',
                opacity: menuState ? 1 : 0,
              }}
            >
              <CloseSvg style={{ height: 35, width: 35 }}></CloseSvg>
            </div>
          </div>
        </div>
      </div>

      {containTrans((style, item, t, i) => {
        return !item ? null : (
          <animated.div
            className="container-fluid vh-100 w-100 position-absolute"
            key={i}
            style={{ top: 0, left: 0, ...style }}
          >
            <div className="row h-100" style={{}}>
              <div className="col-12 d-flex flex-column justify-content-center justify-content-around bg-black">
                <div className="ps-4">
                  {transition((style, item, t, i) => {
                    return (
                      <animated.div key={i} style={style}>
                        <Link
                          to={item.to}
                          className=" text-decoration-none text-white"
                          style={{ color: NAV_DESELECTED_COLOR, opacity: 0.5 }}
                          activeStyle={{ opacity: 1, borderBottom: '2px solid black', fontWeight: 600 }}
                          onClick={toggle}
                        >
                          <h4 className="my-3">{item.title}</h4>
                        </Link>
                      </animated.div>
                    )
                  })}
                </div>
                <div className="px-4 mb-4">
                  <Link to="/term-conditions" className="text-white text-decoration-none">
                    <p className="text-white opacity-50 " style={{ wordBreak: 'break-word' }}>
                      Handels- og forretningsbestingelser
                    </p>
                  </Link>
                  <p className="text-white mb-1 mt-2 pr-md-2 " style={{ fontSize: '.85rem', opacity: 0.8 }}>
                    {CONTACT.address}, {CONTACT.zip},
                    <br />
                    {CONTACT.city}
                    <br />
                    {CONTACT.country}
                  </p>
                </div>
              </div>
            </div>
          </animated.div>
        )
      })}
    </div>
  )
}
export default MobileNavigation
