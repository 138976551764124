exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bademoebler-js": () => import("./../../../src/pages/bademoebler.js" /* webpackChunkName: "component---src-pages-bademoebler-js" */),
  "component---src-pages-bademoebler-template-js": () => import("./../../../src/pages/BademoeblerTemplate.js" /* webpackChunkName: "component---src-pages-bademoebler-template-js" */),
  "component---src-pages-design-spec-js": () => import("./../../../src/pages/design-spec.js" /* webpackChunkName: "component---src-pages-design-spec-js" */),
  "component---src-pages-garderobe-js": () => import("./../../../src/pages/garderobe.js" /* webpackChunkName: "component---src-pages-garderobe-js" */),
  "component---src-pages-garderobe-template-js": () => import("./../../../src/pages/GarderobeTemplate.js" /* webpackChunkName: "component---src-pages-garderobe-template-js" */),
  "component---src-pages-hvordan-js": () => import("./../../../src/pages/hvordan.js" /* webpackChunkName: "component---src-pages-hvordan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koekken-js": () => import("./../../../src/pages/koekken.js" /* webpackChunkName: "component---src-pages-koekken-js" */),
  "component---src-pages-koekken-template-js": () => import("./../../../src/pages/KoekkenTemplate.js" /* webpackChunkName: "component---src-pages-koekken-template-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-pages-skuffer-js": () => import("./../../../src/pages/skuffer.js" /* webpackChunkName: "component---src-pages-skuffer-js" */),
  "component---src-pages-tak-js": () => import("./../../../src/pages/tak.js" /* webpackChunkName: "component---src-pages-tak-js" */),
  "component---src-pages-term-conditions-js": () => import("./../../../src/pages/term-conditions.js" /* webpackChunkName: "component---src-pages-term-conditions-js" */)
}

