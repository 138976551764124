/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-08-2020.
 */
import React from 'react'

import { useHover } from '@use-gesture/react'
import { animated, easings, useSpring } from '@react-spring/web'

//const on = '#e2e2e2'
//const off = '#1d1d1d'

const Button = ({
  title = 'title',
  className = '',
  style = {},
  on = '#FFFFFF',
  off = '#3C4043',
  txtOn = '#000000',
  txtOff = '#FFFFFF',
  onClick = null,
  labelStyle = {},
}) => {
  const borderShadowActive = 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 1px'
  const borderShadowOff = 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(51, 51, 51, 0) 0px 0px 0px 1px'

  const [props, api] = useSpring(() => {
    return {
      from: { backgroundColor: on === '#ffffff' ? off : on, boxShadow: borderShadowOff },
      to: { backgroundColor: off, boxShadow: borderShadowActive },
      //config: { tension: 500, friction: 50, clamp: true },
      config: { easing: easings.easeOutExpo },
      immediate: true,
    }
  })

  const [txtProps, txtApi] = useSpring(() => {
    return {
      from: { color: txtOn },
      to: { color: txtOff },
      //config: { tension: 500, friction: 50, clamp: true },
      config: { easing: easings.easeOutExpo },
      immediate: true,
    }
  })

  const bind = useHover(({ active }) => {
    api.start({
      backgroundColor: active ? on : off,
      boxShadow: active ? borderShadowActive : borderShadowOff,
    })

    txtApi.start({ color: active ? txtOn : txtOff })
  })

  const click = () => {
    if (onClick) {
      onClick()
    }
  }

  const handleKeyDown = (ev) => {
    // check keys if you want
    if (ev.keyCode === 13) {
    }
  }

  return (
    <animated.div
      {...bind()}
      onClick={click}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role="button"
      className={'pointer px-3 text-decoration-none noselect ' + className}
      style={{
        background: off,
        border: '1px solid rgba(0,0,0,0)',
        boxShadow: '0px 1px 6px rgba(0,0,0,.2)',
        borderRadius: 50,
        paddingTop: 10,
        paddingBottom: 11,
        ...style,
        ...props,
      }}
    >
      <animated.p
        className="text-center fw-bold mb-0 "
        style={{ fontSize: '.8rem', color: txtOff, ...labelStyle, ...txtProps }}
      >
        {title}
      </animated.p>
    </animated.div>
  )
}
export default Button

export const InvertedButton = (props) => {
  const obj = { ...props, off: '#FFFFFF', on: '#737677', txtOn: '#FFFFFF', txtOff: '#000000' }
  return <Button {...obj}></Button>
}
