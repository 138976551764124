/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19/05/2022.
 */
import React, { useRef, useState } from 'react'
import { animated, useTransition } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import DropArrow from '../assets/dropdown.svg'
import { Link } from 'gatsby'
import useOnClickOutside from 'use-onclickoutside'
import { NAV_DESELECTED_COLOR } from '../constant'

export const DropDown = ({ items, pathname, selected }) => {
  //const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = 'location' //
  //console.log(' DropDown > location.location = ', location) //location && location.location ? location.location === rootPath : false

  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const bind = useGesture({
    onHover: ({ active }) => {
      setShow(active)
    },
    onDrag: ({ active }) => {
      setShow(true)
    },
  })

  const transition = useTransition(show, {
    from: { opacity: 0, transform: 'scale(0.9,0.5)' },
    enter: {
      opacity: 1,
      transform: 'scale(1,1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(0.5,0.2)',
    },
    config: { friction: 30, tension: 700 },
  })

  useOnClickOutside(ref, () => {
    setShow(false)
  })

  const activeStyle = { color: 'black', fontWeight: 600 }
  const inStyle = { color: NAV_DESELECTED_COLOR, fontWeight: 400 }

  return (
    <div {...bind()} ref={ref} className="position-relative mx-4">
      <div className="noselect pointer d-flex mt-3 pt-1">
        <p className="p-nav me-1" style={selected ? activeStyle : inStyle}>
          Kollektion
        </p>

        <DropArrow style={{ marginTop: 2, opacity: isRootPath ? 1 : 0.5, width: '.9rem' }}></DropArrow>
      </div>
      {transition((style, item, t, i) => {
        return item ? (
          <animated.div
            key={i}
            className="position-absolute p-3"
            style={{
              zIndex: 100,
              top: 60,
              left: -20,
              background: 'white',
              boxShadow: '0px 5px 25px -5px rgba(0, 0, 0, 0.25)',
              transformOrigin: 'top center',
              ...style,
            }}
          >
            <div className="">
              {items.map((item, index) => {
                //                const isActive = false
                return (
                  <Link
                    to={item.to}
                    key={index}
                    style={{ color: NAV_DESELECTED_COLOR }}
                    activeStyle={{ color: 'black', fontWeight: 600 }}
                    className="pointer btn text-left ps-0 "
                  >
                    <p key={index} className="noselect p-nav mb-0">
                      {item.title}
                    </p>
                  </Link>
                )
              })}
            </div>
          </animated.div>
        ) : null
      })}
    </div>
  )
}
